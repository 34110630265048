<template>
  <v-row no-gutters class="container bg-secondary-background">
    <v-row no-gutters class="title">
      <p class="heading subheading-size">First of its kind!</p>
    </v-row>
    <v-row no-gutters class="subtitle">
      <p class="heading title-size">
        Share your fundraising pitch materials in URL format
      </p>
    </v-row>
    <v-row no-gutters class="body">
      <v-img
        :src="require('../../assets/email.gif')"
        alt="App Logo"
        max-width="60%"
        min-width="400"
        contain
      />
    </v-row>
  </v-row>
</template>

<style scoped>
.v-btn {
  margin-top: 5%;
  font-weight: 800;
  text-decoration: underline;
}
.title {
  height: 10vh;
  width: 100vw;
  margin-top: 2%;
}
.subtitle {
  height: fit-content;
  width: 100vw;
}
.body {
  height: 70vh;
}
</style>
