import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useDate } from "vuetify";

export const useAnalyticsStore = defineStore("AnalyticsStore", () => {
  const date = useDate();
  const views = [
    {
      date: new Date("2024-04-01"),
      views: 97,
    },
    {
      date: new Date("2024-04-02"),
      views: 40,
    },
    {
      date: new Date("2024-04-03"),
      views: 68,
    },
    {
      date: new Date("2024-04-04"),
      views: 14,
    },
    {
      date: new Date("2024-04-05"),
      views: 3,
    },
    {
      date: new Date("2024-04-06"),
      views: 50,
    },
    {
      date: new Date("2024-04-07"),
      views: 52,
    },
    {
      date: new Date("2024-04-08"),
      views: 72,
    },
    {
      date: new Date("2024-04-09"),
      views: 33,
    },
    {
      date: new Date("2024-04-10"),
      views: 95,
    },
    {
      date: new Date("2024-04-11"),
      views: 44,
    },
    {
      date: new Date("2024-04-12"),
      views: 97,
    },
    {
      date: new Date("2024-04-13"),
      views: 90,
    },
    {
      date: new Date("2024-04-14"),
      views: 81,
    },
    {
      date: new Date("2024-04-15"),
      views: 91,
    },
    {
      date: new Date("2024-04-16"),
      views: 43,
    },
    {
      date: new Date("2024-04-17"),
      views: 97,
    },
    {
      date: new Date("2024-04-18"),
      views: 7,
    },
    {
      date: new Date("2024-04-19"),
      views: 3,
    },
    {
      date: new Date("2024-04-20"),
      views: 70,
    },
    {
      date: new Date("2024-04-21"),
      views: 64,
    },
    {
      date: new Date("2024-04-22"),
      views: 82,
    },
    {
      date: new Date("2024-04-23"),
      views: 11,
    },
    {
      date: new Date("2024-04-24"),
      views: 41,
    },
    {
      date: new Date("2024-04-25"),
      views: 25,
    },
    {
      date: new Date("2024-04-26"),
      views: 42,
    },
    {
      date: new Date("2024-04-27"),
      views: 96,
    },
    {
      date: new Date("2024-04-28"),
      views: 40,
    },
    {
      date: new Date("2024-04-29"),
      views: 9,
    },
  ];
  const selectedDates = ref([]);
  const filteredViews = computed(() => {
    if (selectedDates.value.length < 1) {
      return views;
    }

    const formattedDates = selectedDates.value.map((x) => {
      return date.format(x, "keyboardDate");
    });

    return views.filter((x) => {
      return formattedDates.includes(date.format(x.date, "keyboardDate"));
    });
  });

  const setSelectedDates = (dates) => {
    selectedDates.value = dates;
  };

  return {
    selectedDates,
    filteredViews,
    setSelectedDates,
  };
});
