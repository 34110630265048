<template>
  <v-row
    no-gutters
    :class="
      withNav ? 'container-with-nav' : 'container' + ' align-space-around'
    "
  >
    <v-row class="title" v-if="title">
      <v-col md="5" xs="12" class="justify-content-center">
        <p class="heading subheading-size">{{ title }}</p>
      </v-col>
    </v-row>
    <v-row no-gutters class="content no-overflow align-center">
      <v-col
        :cols="props.leftCols"
        class="align-center justify-content-center no-overflow"
      >
        <props.leftLayout />
      </v-col>
      <v-col
        :cols="props.rightCols"
        class="align-center justify-content-center no-overflow"
      >
        <VectorImage :photo="props.photo" v-if="photo" />
        <props.rightLayout v-else />
      </v-col>
    </v-row>
  </v-row>
</template>

<script setup>
import { defineProps } from "vue";
import VectorImage from "./VectorImage.vue";

const props = defineProps([
  "leftCols",
  "rightCols",
  "photo",
  "withNav",
  "leftLayout",
  "rightLayout",
  "title",
]);
</script>

<style scoped>
.title {
  height: fit-content;
  width: 100vw;
}
.content {
  height: 100%;
}
.v-col {
  height: 100%;
}
</style>
