<template>
  <v-dialog
    v-model="isActive"
    @click:outside="closeDialog"
    :class="isMobile ? 'mobile-width' : 'normal-width'"
  >
    <v-card>
      <v-card-title class="text-primary title body-size justify-content-center">
        Follow fundraise?
      </v-card-title>
      <v-card-text>
        <v-row>
          <p class="mb-3">
            Want to follow progress of this fundraise? Enter your email below!
          </p>
          <v-combobox
            v-model="email"
            :hideSelected="true"
            label="Email"
            :multiple="false"
            :chips="false"
            :clearable="true"
            :rules="[rules.required, rules.email]"
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn text="Cancel" @click="closeDialog"></v-btn>

        <v-btn
          class="bg-primary primary-button"
          text="Follow"
          variant="flat"
          @click="followFundraise"
          :disabled="!emailPattern.test(email) || email === ''"
          :loading="isLoading"
        ></v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <SuccessDialog
    v-model="openSuccessDialog"
    title="Followed Fundraise!"
    message="Thank you for following this pitch. You will receive updates through your email."
    @close-dialog="closeSuccessDialog"
  />
</template>

<script setup>
import { ref, defineProps, defineEmits, watchEffect } from "vue";
import { useCommonStore } from "@/store/common";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { db } from "@/firebase/init";
import { doc, setDoc, arrayUnion } from "firebase/firestore";
import SuccessDialog from "./SuccessDialog.vue";

const commonStore = useCommonStore();
const props = defineProps(["modelValue"]);
const emit = defineEmits(["close-dialog"]);
const route = useRoute();
const userId = route.params.userId;

const { isMobile, rules, emailPattern } = storeToRefs(commonStore);

const isActive = ref(false);
const email = ref("");
let openSuccessDialog = ref(false);
let isLoading = ref(false);

const followFundraise = async () => {
  isLoading.value = true;
  const userRef = doc(db, "user", userId);

  await setDoc(
    userRef,
    {
      followers: arrayUnion(email.value),
    },
    { merge: true }
  );

  isActive.value = isLoading.value = false;
  openSuccessDialog.value = true;

  closeDialog();
};
const closeDialog = () => {
  emit("close-dialog");
};
const closeSuccessDialog = () => {
  openSuccessDialog.value = false;
};

watchEffect(() => {
  isActive.value = props.modelValue;
});
</script>

<style scoped>
.v-dialog {
  height: 50%;
  flex-direction: column;
}
.mobile-width {
  max-width: 95%;
}
.normal-width {
  max-width: 50%;
}
.v-card {
  height: 100vh;
  padding: 3%;
}
</style>
