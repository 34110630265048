<template>
  <v-row no-gutters class="container bg-secondary-background main">
    <v-row>
      <v-col md="7" xs="12">
        <p class="heading title-size">
          Easily and quickly share the right fund raising materials to
          investors.
        </p>
      </v-col>
    </v-row>

    <v-row class="justify-content-center">
      <p class="title subtitle-size">Guided Templates</p>
    </v-row>
    <v-row class="justify-content-center">
      <v-img
        :src="require('../../assets/guided-template-vector.png')"
        alt="App Logo"
        width="80%"
        max-width="200"
        class="mx-md-16 mx-xs-0"
        contain
      />
    </v-row>
    <v-row class="justify-content-center">
      <v-col md="4" xs="12" class="justify-content-center">
        <p class="heading body-size text-center">
          Easy to use template that comes with guidelines and tips to aide you
          as you build your pitch documents.
        </p>
      </v-col>
    </v-row>
  </v-row>
</template>

<style scoped>
.v-row {
  width: 100%;
  padding: 1%;
  padding-bottom: 0;
}
.title {
  font-weight: 800;
}
</style>
