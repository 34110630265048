<template>
  <pdfvuer
    :src="pdfdata"
    v-for="i in numPages"
    :key="i"
    :id="i"
    :page="i"
    :annotation="true"
    :resize="true"
    style="width: 90%; margin: 20px auto"
  />
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { usePitchStore } from "@/store/pitch";
import { storeToRefs } from "pinia";
import pdfvuer from "pdfvuer";

const pitchStore = usePitchStore();
const { pitch, currentPitch } = storeToRefs(pitchStore);

const pdf = ref(null);
const pdfdata = ref(null);
let numPages = ref(0);

watchEffect(() => {
  pdf.value = pitch.value[currentPitch.value].url;

  if (pdf.value) {
    pdfdata.value = pdfvuer.createLoadingTask(pdf.value);
    pdfdata.value.then((pdf) => {
      numPages.value = pdf.numPages;
    });
  }
});
</script>
