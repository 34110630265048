<template>
  <v-dialog v-model="isActive" width="auto">
    <v-card max-width="400" :text="props.message">
      <template v-slot:prepend>
        <v-icon color="primary" icon="mdi-check-bold" />
      </template>
      <template v-slot:title>
        <p class="text-primary">{{ props.title }}</p>
      </template>
      <template v-slot:actions>
        <v-btn class="ms-auto" text="Ok" @click="isActive = false"></v-btn>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref, defineProps, watchEffect } from "vue";

const props = defineProps(["modelValue", "title", "message"]);
const isActive = ref(true);

watchEffect(() => {
  isActive.value = props.modelValue;
});
</script>
