<template>
  <v-row
    class="drop-zone justify-content-center align-center"
    @dragenter.prevent="toggleActive"
    @dragleave.prevent="toggleActive"
    @dragover.prevent
    @drop.prevent="getFiles"
    @change="getSelectedFiles"
    :class="{ 'active-dropzone': active }"
  >
    <p>Drag and drop PDF file</p>
    <p>or</p>
    <label for="onePagerFile">Upload PDF file</label>
    <p>or</p>
    <label class="bg-primary" @click="toggleDialog = true"
      >Use Pitchly's Template</label
    >
    <input type="file" id="onePagerFile" class="onePagerFile" accept=".pdf" />
    <p>PDF File: {{ filename }}</p>
  </v-row>
  <OnePagerDialog
    v-model="toggleDialog"
    @close-dialog="closePublishDialog"
    @is-template-answered="isTemplateAnswered"
  />
</template>

<script setup>
import { onMounted, ref, watchEffect, defineEmits } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/store/user";
import { usePitchStore } from "@/store/pitch";
import {
  ref as storageRef,
  getStorage,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { db } from "@/firebase/init";
import { doc, setDoc } from "firebase/firestore";
import OnePagerDialog from "@/components/Dialogs/OnePagerDialog.vue";

const emit = defineEmits(["disableNextButton"]);

const userStore = useUserStore();
const pitchStore = usePitchStore();

const { setOnePagerFile } = pitchStore;

const { currentUser } = storeToRefs(userStore);
const { pitch } = storeToRefs(pitchStore);

const active = ref(false);
let file = ref("");
let filename = ref("");
let toggleDialog = ref(false);

const storageFolder = "onepagers/";

const closePublishDialog = () => {
  toggleDialog.value = false;
};
const isTemplateAnswered = (value) => {
  if (value) {
    filename.value = "One-Pager.pdf";
  }
};
const toggleActive = () => {
  active.value = !active.value;
};
const getFiles = async (event) => {
  file.value = event.dataTransfer.files[0];
  await getFirebaseData();
  toggleActive();
};
const getSelectedFiles = async () => {
  file.value = document.querySelector(".onePagerFile").files[0];
  await getFirebaseData();
};

const getFirebaseData = async () =>{
  try{
  const fbStorage = getStorage();
  const fbStorageRef = storageRef(fbStorage, storageFolder + file.value.name);

  uploadBytes(fbStorageRef, file.value).then((snapshot) => {
    console.log(snapshot);
    console.log("Uploaded a blob or file!");
  });
  const snapshot = await uploadBytes(fbStorageRef, file.value);
  console.log(snapshot);
  const downloadURL = await getDownloadURL(storageRef(fbStorageRef));

  await setDoc(doc(db, "onepagers", currentUser.value.uid), {
    downloadURL: downloadURL,
    filename: file.value.name
  });
  setOnePagerFile({
    filename: file.value.name,
    url: downloadURL,
  });
  }
  catch(e){
  console.log(e);
}
}

//lifecycle hooks
onMounted(() => {
  file.value = pitch.value.onePager;
});

watchEffect(() => {
  filename.value =
    file.value !== ""
      ? file.value.name
        ? file.value.name
        : file.value.filename !== ""
        ? file.value.filename
        : "No PDF file uploaded"
      : "No PDF file uploaded";

  emit("disableNextButton", pitch.value.pitchDeck.url === "");
});
</script>

<style scoped>
.drop-zone {
  margin-top: 3%;
  height: 43vh;
  width: 100%;
  flex-direction: column;
  border: 2px dashed #4c6fbf;

  label {
    padding: 1% 2%;
    background-color: #4c6fbf;
    color: white !important;
    margin: 2%;
  }

  input {
    display: none;
  }
}
.active-dropzone {
  color: white;
  border-color: white;
  background-color: #4c6fbf;

  label {
    background-color: white;
    color: #4c6fbf !important;
    margin: 2%;
  }
}
</style>
