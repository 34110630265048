<template>
  <v-row no-gutters class="container align-center justify-content-center">
    <v-col md="6" xs="12"
      ><v-row>
        <p class="heading subheading-size">Other Features You'll Enjoy</p>
      </v-row>
      <v-row class="ml-10">
        <ul>
          <li
            class="heading body-size"
            v-for="(item, index) in features"
            :key="index"
          >
            {{ item }}
          </li>
        </ul>
      </v-row>
      <v-row> <GetStartedButton marginTop="5%" /> </v-row
    ></v-col>
  </v-row>
</template>

<script setup>
import { ref } from "vue";
import GetStartedButton from "./GetStartedButton.vue";

const features = ref([
  "FREE Easy-to-use Templates",
  "FREE Tips and Guides",
  "Integreated Video Recording of your pitch",
  "Analytics",
  "Built-in update blast to investors",
  "URL based document sharing",
  "Directory of fundraising consultants (soon)",
]);
</script>
