<template>
  <v-btn
    :variant="props.variant"
    color="primary"
    height="70"
    class="subtitle-size no-uppercase"
    to="/signup"
    :style="
      props.variant == 'text'
        ? 'color: black !important; text-decoration: underline; margin-top: ' +
          props.marginTop +
          ';'
        : 'margin-top: ' + props.marginTop + ';'
    "
  >
    Get Started
  </v-btn>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps(["variant", "marginTop"]);
</script>

<style scoped>
.v-btn {
  font-weight: 800;
  min-width: 250px;
}
</style>
