<template>
  <NavBar :show-sign-up-log-in="true" />
  <TwoColLayout
    :leftCols="isMobile ? 12 : 7"
    :rightCols="isMobile ? 0 : 5"
    photo="home-intro-vector.png"
    :withNav="true"
    :leftLayout="Introduction"
  />
  <GuidedTemplate />
  <InstantView />
  <ViewAnalytics />
  <TwoColLayout
    :leftCols="isMobile ? 12 : 6"
    :rightCols="isMobile ? 0 : 6"
    photo="target-users-vector.png"
    :withNav="false"
    :leftLayout="TargetUsers"
    title="Who is Pitchly for?"
  />
  <EmailShare />
  <OtherFeatures />
  <Ending />
</template>

<script setup>
import { useCommonStore } from "@/store/common";
import { storeToRefs } from "pinia";
import NavBar from "@/components/NavBar.vue";
import TwoColLayout from "@/components/TwoColLayout.vue";
import Introduction from "@/components/HomePage/HomeIntro.vue";
import GuidedTemplate from "@/components/HomePage/GuidedTemplate.vue";
import InstantView from "@/components/HomePage/InstantView.vue";
import ViewAnalytics from "@/components/HomePage/ViewAnalytics.vue";
import TargetUsers from "@/components/HomePage/TargetUsers.vue";
import EmailShare from "@/components/HomePage/EmailShare.vue";
import OtherFeatures from "@/components/HomePage/OtherFeatures.vue";
import Ending from "@/components/HomePage/HomeEnd.vue";

const commonStore = useCommonStore();
const { isMobile } = storeToRefs(commonStore);
</script>
