import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyD2ZYqkQvRe7Uhjv3NBt8BRMdBe0vOuoaY",
    authDomain: "pitchly-47949.firebaseapp.com",
    projectId: "pitchly-47949",
    storageBucket: "gs://pitchly-47949.appspot.com",
    messagingSenderId: "116431772272",
    appId: "1:116431772272:web:ec83e307948742827e72b5",
    measurementId: "G-G5BGMNCFZR"
  };
  

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export {db,auth, storage}
