<template>
  <v-form fast-fail @submit.prevent class="bg-background">
    <v-text-field
      v-model="firstName"
      label="First Name of Founder*"
      :rules="[rules.required]"
    />
    <v-text-field
      v-model="lastName"
      label="Last Name of Founder*"
      :rules="[rules.required]"
    />
    <v-text-field
      v-model="contactNumber"
      label="Mobile Number*"
      :rules="[rules.required]"
    />
    <v-text-field
      v-model="contactEmail"
      label="Email Address*"
      :rules="[rules.required, rules.email]"
    />
    <v-text-field
      v-model="password"
      label="Password*"
      :rules="[rules.required, rules.password]"
      :append-inner-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
      :type="passwordVisible ? 'text' : 'password'"
      @click:append-inner="passwordVisible = !passwordVisible"
    />
    <v-text-field
      v-model="confirmPassword"
      label="Confirm Password*"
      :rules="[
        rules.required,
        (value) => value === password || 'Passwords must match.',
      ]"
      :append-inner-icon="confirmPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'"
      :type="confirmPasswordVisible ? 'text' : 'password'"
      @click:append-inner="confirmPasswordVisible = !confirmPasswordVisible"
    />
  </v-form>
</template>

<script setup>
import { ref, watchEffect, defineEmits } from "vue";
import { useCommonStore } from "@/store/common";
import { useUserStore } from "@/store/user";
import { storeToRefs } from "pinia";

const emit = defineEmits(["disableNextButton"]);

const commonStore = useCommonStore();
const userStore = useUserStore();
const { setUserDetails } = userStore;
const { rules, emailPattern } = storeToRefs(commonStore);

const passwordVisible = ref(false);
const confirmPasswordVisible = ref(false);
const firstName = ref("");
const lastName = ref("");
const contactNumber = ref("");
const contactEmail = ref("");
const password = ref("");
const confirmPassword = ref("");

watchEffect(() => {
  setUserDetails({
    firstName: firstName.value,
    lastName: lastName.value,
    contactNumber: contactNumber.value,
    contactEmail: contactEmail.value,
    password: password.value,
  });

  emit(
    "disableNextButton",
    firstName.value === "" ||
      lastName.value === "" ||
      contactNumber.value === "" ||
      contactEmail.value === "" ||
      password.value === "" ||
      confirmPassword.value === "" ||
      password.value.length < 6 ||
      confirmPassword.value.length < 6 ||
      password.value !== confirmPassword.value ||
      !emailPattern.value.test(contactEmail.value)
  );
});
</script>

<style scoped>
.v-form {
  width: 100%;
  margin-top: 3%;
}
</style>
