<template>
  <v-row class="justify-content-center">
    <v-col cols="5" class="align-center">
      <v-text-field
        label="Start date"
        v-model="fromDate"
        readonly
        density="compact"
      />
    </v-col>
    <v-col cols="2" class="justify-content-center align-center">
      <p>to</p>
    </v-col>
    <v-col cols="5" class="align-center">
      <v-text-field
        label="End date"
        v-model="toDate"
        readonly
        density="compact"
      />
    </v-col>
    <v-col cols="12">
      <v-date-picker
        v-model="dates"
        multiple="range"
        hide-header
        color="secondary-blue"
        width="400"
        :month="date.getMonth(today)"
        class="bg-background"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import { onMounted, ref, watchEffect, computed } from "vue";
import { useDate } from "vuetify";
import { useAnalyticsStore } from "@/store/analytics";

const analyticsStore = useAnalyticsStore();
const date = useDate();
const { setSelectedDates } = analyticsStore;

const today = ref(date.addDays(new Date(), -1));
const lastMonth = ref(date.addMonths(today.value, -1));
let dates = ref([]);

onMounted(() => {
  let currentDate = lastMonth.value;

  do {
    dates.value.push(currentDate);
    currentDate = date.addDays(currentDate, 1);
  } while (currentDate <= today.value);
});

watchEffect(() => {
  setSelectedDates(dates.value);
});
const fromDate = computed(() => {
  return dates.value.length > 0
    ? date.format(dates.value[0], "keyboardDate")
    : date.format(lastMonth.value, "keyboardDate");
});
const toDate = computed(() => {
  return dates.value.length > 1
    ? date.format(dates.value[dates.value.length - 1], "keyboardDate")
    : dates.value.length > 0
    ? date.format(dates.value[dates.value.length - 1], "keyboardDate")
    : date.format(today.value, "keyboardDate");
});
</script>
