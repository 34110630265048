<template>
  <v-stepper alt-labels v-model="currentStep" class="bg-background">
    <template v-slot>
      <v-stepper-header>
        <template v-for="(step, index) in steps" :key="`${step.title}-step`">
          <v-stepper-item
            :complete="currentStep > index + 1"
            :value="index + 1"
            :title="step.title"
            :color="currentStep > index + 1 ? 'primary' : ''"
            :class="isMobile ? 'mobile-width-header' : ''"
          />

          <v-divider
            v-if="index + 1 !== steps.length"
            :key="step.title"
            :class="isMobile ? 'mobile-width-header' : ''"
          ></v-divider>
        </template>
      </v-stepper-header>

      <v-row class="content">
        <v-stepper-window>
          <v-stepper-window-item
            v-for="(step, index) in steps"
            :key="`${step.title}-content`"
            :value="index + 1"
          >
            <template v-slot>
              <p class="subheading body-size text-primary">
                {{ step.title }}
              </p>
              <component
                :is="step.content"
                @disable-next-button="disableNextButton"
              />
            </template>
          </v-stepper-window-item>
        </v-stepper-window>
        <v-row class="align-center justify-content-end footer">
          <v-col cols="6">
            <p>
              <a href="/login" class="text-secondary-blue">Log-in</a>
              instead?
            </p>
          </v-col>
          <v-col cols="3">
            <v-btn
              variant="text"
              :disabled="currentStep < 0"
              @click="prevStep"
              >{{ currentStep === 1 ? "Cancel" : "Previous" }}</v-btn
            >
          </v-col>
          <v-col cols="3" class="justify-content-end">
            <v-btn
              :disabled="disableNextStep"
              class="bg-primary"
              @click="nextStep"
              :loading="isSigningUp"
              >{{ currentStep === steps.length ? "Sign-up" : "Next" }}</v-btn
            >
          </v-col>
        </v-row>
      </v-row>
    </template>
  </v-stepper>

  <ErrorDialog
    v-model="openErrorDialog"
    title="Log-in failed!"
    :message="errorMsg"
    @close-dialog="closeErrorDialog"
  />
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store/common";
import { useUserStore } from "@/store/user";
import { useCompanyStore } from "@/store/company";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "@/firebase/init";
import CompanyStep from "./SignUpFormSteps/CompanyStep.vue";
import InvestmentStep from "./SignUpFormSteps/InvestmentStep.vue";
import UserStep from "./SignUpFormSteps/UserStep.vue";
import ErrorDialog from "../Dialogs/ErrorDialog.vue";

const router = useRouter();

const commonStore = useCommonStore();
const userStore = useUserStore();
const companyStore = useCompanyStore();

const { isMobile } = storeToRefs(commonStore);
const { currentUser } = storeToRefs(userStore);
const { company } = storeToRefs(companyStore);

let currentStep = ref(1);
const steps = [
  {
    title: "Startup Details",
    content: CompanyStep,
  },
  {
    title: "Investment Details",
    content: InvestmentStep,
  },
  {
    title: "User Details",
    content: UserStep,
  },
];
let disableNextStep = ref(false);
let isSigningUp = ref(false);
let openErrorDialog = ref(false);
let errorMsg = ref("");

const prevStep = () => {
  if (currentStep.value > 1) {
    currentStep.value--;
  } else if (currentStep.value === 1) {
    router.push("/");
  }
};
const nextStep = async () => {
  if (currentStep.value < steps.length) {
    currentStep.value++;
  } else if (currentStep.value === steps.length) {
    isSigningUp.value = true;
    const email = currentUser.value.contactEmail;
    const password = currentUser.value.password;
    const firstName = currentUser.value.firstName;
    const lastName = currentUser.value.lastName;
    const mobileNumber = currentUser.value.contactNumber;

    const companyName = company.value.companyName;
    const companyDescription = company.value.description;
    const tags = company.value.tags;
    const stage = company.value.stage;

    const amountRaising = company.value.amountRaising;
    const terms = company.value.investmentTerm;
    const offer = company.value.offer;

    console.log(password + email);

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      const companyRef = await addDoc(collection(db, "company"), {
        companyName: companyName,
        companyDescription: companyDescription,
        amountRaising: amountRaising,
        stage: stage,
        terms: terms,
        tags: tags,
        offer: offer,
      });

      await setDoc(doc(db, "user", userCredential.user.uid), {
        firstName: firstName,
        lastName: lastName,
        mobileNumber: mobileNumber,
        email: email,
        company: companyRef.id,
      });

      router.push("/login");
    } catch (e) {
      isSigningUp.value = false;
      errorMsg.value = e.message;
      openErrorDialog.value = true;
      console.log(e);
    }
  }
};
const disableNextButton = (value) => {
  disableNextStep.value = value;
};
const closeErrorDialog = () => {
  openErrorDialog.value = false;
};

watchEffect(() => {
  disableNextStep.value = currentStep.value > steps.length;
});
</script>

<style scoped>
.v-stepper {
  height: 80%;
  width: 90%;
}
.v-stepper-window {
  max-height: 70%;
  width: 100%;
  overflow-y: auto;
}
.v-btn {
  width: 100%;
  margin: 0;
}
.mobile-width-header {
  max-width: 100px;
}
.content {
  height: 80%;
  padding-bottom: 1%;
}
.footer {
  margin-top: auto;
  margin-bottom: 1%;
}
</style>
