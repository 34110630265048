<template>
  <v-row no-gutters class="container bg-secondary-background align-center">
    <v-row>
      <v-row class="justify-content-center">
        <v-img
          :src="require('../../assets/pitchly-logo-transparent.png')"
          alt="App Logo"
          width="40%"
          max-width="400"
          contain
        />
      </v-row>
      <v-row class="justify-content-center">
        <p class="heading subheading-size text-center">
          Making fund raising for founders a little easier
        </p>
      </v-row>
      <v-row class="justify-content-center">
        <p class="heading title-size">www.pitchly.online</p>
      </v-row>
    </v-row>
  </v-row>
</template>

<style scoped>
.v-row {
  width: 100%;
}
</style>
