<template>
  <v-form fast-fail @submit.prevent class="bg-background">
    <v-text-field
      v-model="companyName"
      label="Startup Name*"
      :rules="[rules.required]"
    />
    <v-textarea
      v-model="description"
      label="Description*"
      :rules="[rules.required]"
      no-resize
    />
    <v-select
      v-model="stage"
      :items="stages"
      label="Startup Stage*"
      :rules="[rules.required]"
    />
    <v-combobox
      v-model="tags"
      v-model:search="searchValue"
      :hideSelected="true"
      :items="tags"
      label="Tags"
      :multiple="true"
      :chips="true"
      :clearable="true"
      clear-icon="$clear"
      hint="Type # that refers to your startup i.e. #fintech #seed #launch. Maximum of 6 tags"
      persistent-hint
    />
  </v-form>
</template>

<script setup>
import { ref, watch, watchEffect, defineEmits } from "vue";
import { useCommonStore } from "@/store/common";
import { useCompanyStore } from "@/store/company";
import { useOnePagerStore } from "@/store/onePager";
import { storeToRefs } from "pinia";

const emit = defineEmits(["disableNextButton"]);

const commonStore = useCommonStore();
const companyStore = useCompanyStore();
const onePagerStore = useOnePagerStore();

const { setCompanyDetails } = companyStore;

const { rules } = storeToRefs(commonStore);
const { stages } = storeToRefs(onePagerStore);

let companyName = ref("");
let description = ref("");
let stage = ref(stages.value[0]);
let searchValue = ref();
let tags = ref([]);

watch(tags, (val) => {
  if (val.length > 6) {
    tags.value.pop();
  }
});

watchEffect(() => {
  setCompanyDetails({
    companyName: companyName.value,
    description: description.value,
    stage: stage.value,
    tags: tags.value,
  });

  emit(
    "disableNextButton",
    companyName.value === "" || description.value === ""
  );
});
</script>

<style scoped>
.v-form {
  width: 100%;
  margin-top: 3%;
}
</style>
