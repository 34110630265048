import { defineStore } from "pinia";
import { ref } from "vue";
import OnePagerAide from "@/components/AideContent/OnePagerAide.vue";
import ElevatorPitchAide from "@/components/AideContent/ElevatorPitchAide.vue";
import PitchDeckAide from "@/components/AideContent/PitchDeckAide.vue";

export const useAideStore = defineStore("AideStore", () => {
  const aides = [
    {
      title: "Guide to an Effective Pitch Deck",
      component: PitchDeckAide,
    },
    {
      title: "What is an Elevator Pitch?",
      component: ElevatorPitchAide,
    },
    {
      title: "What is a One-Pager?",
      component: OnePagerAide,
    },
  ];

  const currentAide = ref({
    title: "",
    content: null,
    component: null,
  });

  const setAide = (step, note = 0) => {
    const index = step + note;
    const { title, content, component } = aides[index];
    currentAide.value.title = title;
    currentAide.value.content = content;
    currentAide.value.component = component;
  };

  return {
    currentAide,
    setAide,
  };
});
