<template>
  <v-row class="align-center justify-content-center">
    <v-sheet color="background">
      <Line :data="data" :options="options" />
    </v-sheet>
  </v-row>
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { useAnalyticsStore } from "@/store/analytics";
import { storeToRefs } from "pinia";
import { useDate } from "vuetify";
import { Line } from "vue-chartjs";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const analyticsStore = useAnalyticsStore();
const date = useDate();

const { filteredViews } = storeToRefs(analyticsStore);

let data = ref({
  labels: [],
  datasets: [
    {
      label: "Number of Views",
      backgroundColor: "#4C6FBF",
      data: [],
    },
  ],
});
const options = ref({
  scales: {
    x: {
      ticks: {
        color: "#FF9933",
      },
      grid: {
        display: false,
      },
      border: {
        display: true,
      },
    },
    y: {
      ticks: {
        color: "#FF9933",
      },
      grid: {
        drawTicks: false,
      },
      border: {
        dash: [5, 10],
      },
    },
  },
});

watchEffect(() => {
  const label = filteredViews.value.map((view) =>
    date.format(view.date, "keyboardDate")
  );
  const view = filteredViews.value.map((view) => view.views);

  data.value = {
    labels: label,
    datasets: [
      {
        label: "Number of Views",
        backgroundColor: "#4C6FBF",
        data: view,
      },
    ],
  };
});
</script>

<style scoped>
.v-sheet {
  height: 100%;
  width: 100%;
}
</style>
