<template>
  <v-card class="bg-background overflow-container">
    <v-card-item class="bg-primary">
      <v-card-title class="text-white"> Newsletters </v-card-title>
      <v-card-subtitle class="text-white">
        New newsletters are sent out every morning at 3AM.
      </v-card-subtitle>
    </v-card-item>

    <v-card-text class="overflow-content">
      <v-list class="bg-background">
        <v-list-item
          v-for="(newsletter, index) in selectedNewsletters"
          :key="index"
          @click="getNewsletter(newsletter)"
        >
          <v-col cols="11">
            <v-list-item-title> {{ newsletter.title }}</v-list-item-title>
            <v-list-item-subtitle>
              {{
                date.format(newsletter.date, "fullDate")
              }}</v-list-item-subtitle
            >
          </v-col>
          <template v-slot:append>
            <v-chip
              :color="newsletter.isSent === false ? 'primary' : 'success'"
              >
              {{ newsletter.isSent }}
              
              </v-chip
            >
          </template>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { useDate } from "vuetify";
import { useNewsletterStore } from "@/store/newsletter";
import { storeToRefs } from "pinia";

const newsletterStore = useNewsletterStore();

const date = useDate();
const { newsletters: selectedNewsletters } = storeToRefs(newsletterStore);
const { setNewsletter } = newsletterStore;

const getNewsletter = (newsletter) => {
  setNewsletter(newsletter);
};
</script>

<style scoped>
.v-col {
  padding: 0;
}
.v-card {
  height: 80%;
  width: 90%;
}
.v-card-item {
  height: 15%;
}
.v-card-subtitle {
  font-size: 13px;
}
.v-card-text {
  overflow-y: auto;
  height: 85%;
}
.v-list-item {
  padding: 2%;
  margin-bottom: 3%;
}
.v-chip {
  width: 100%;
}
</style>
