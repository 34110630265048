<template>
  <v-row no-gutters class="container align-center bg-secondary-background">
    <v-row class="justify-content-center">
      <v-col md="4" xs="12" class="justify-content-center">
        <p class="title subtitle-size">Instant View</p>
      </v-col>
    </v-row>

    <v-col cols="12" class="justify-content-center">
      <v-img
        :src="require('../../assets/instant-view-vector.png')"
        alt="App Logo"
        width="50%"
        max-width="400"
        contain
      />
    </v-col>
    <v-row class="justify-content-center">
      <v-col md="4" class="justify-content-center">
        <p class="heading body-size text-center">
          Using Pitchly will make yours' and investors' life easy. With our URL
          based pitch documentation, you will save your investor from
          downloading lots of files.
        </p>
      </v-col>
    </v-row>
  </v-row>
</template>

<style scoped>
.title {
  font-weight: 800;
}
</style>
