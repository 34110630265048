<template>
  <NavBar :investorView="true" />
  <PitchContent />
</template>

<script setup>
import NavBar from "@/components/NavBar.vue";
import PitchContent from "@/components/PitchContent.vue";
import { onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { db } from "@/firebase/init";
import { doc, getDoc } from "firebase/firestore";
import { usePitchStore } from "@/store/pitch";

const route = useRoute()
const pitchStore = usePitchStore();
const { setPitch } = pitchStore;
const { setCurrentPitch } = pitchStore;

onMounted(async () => {
  setCurrentPitch("pitchDeck");
  const userId = route.params.userId
  console.log(userId);
  const docRefPitchDeck = doc(db, "pitchdecks", userId);
  const docSnapPitchdeck = await getDoc(docRefPitchDeck);

  const docRefEle = doc(db, "elevatorpitches", userId);
  const docSnapEle = await getDoc(docRefEle);

  const docRefOne = doc(db, "onepagers", userId);
  const docSnapOne = await getDoc(docRefOne);

  setPitch({
    onePager: {
      filename: docSnapOne.data.filename,
      url: docSnapOne.data().downloadURL,
    },
    elevator: {
      filename: docSnapEle.data.filename,
      url: docSnapEle.data().downloadURL,
    },
    pitchDeck: {
      filename: docSnapPitchdeck.data.filename,
      url: docSnapPitchdeck.data().downloadURL,
    },
  });
});

</script>
