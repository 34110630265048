<template>
  <video :src="video" controls autoplay loop />
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { usePitchStore } from "@/store/pitch";
import { storeToRefs } from "pinia";

const pitchStore = usePitchStore();
const { pitch, currentPitch } = storeToRefs(pitchStore);
let video = ref();

watchEffect(() => {
  video.value = pitch.value[currentPitch.value].url;
  // uncomment the above once blob storage is done
  // video.value needs to be a link
});
</script>

<style scoped>
video {
  width: 80%;
  margin: auto;
}
</style>
