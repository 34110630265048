import { defineStore } from "pinia";
import { ref } from "vue";

export const useCompanyStore = defineStore(
  "CompanyStore",
  () => {
    const company = ref({
      companyName: "",
      description: "",
      fundRaisingStage: "",
      stage: "",
      investmentTerm: "",
      offer: "",
      amountRaising: "",
      tags: [],
    });

    const setCompanyDetails = (payload) => {
      const { companyName, description, stage, tags } = payload;
      company.value.companyName = companyName;
      company.value.description = description;
      company.value.stage = stage;
      company.value.tags = tags;
    };

    //NOTE: Add the fundRaisingStage after UI update
    const setInvestmentDetails = (payload) => {
      const { investmentTerm, offer, amountRaising } = payload;
      company.value.investmentTerm = investmentTerm;
      company.value.offer = offer;
      company.value.amountRaising = amountRaising;
    };

    return {
      company,
      setCompanyDetails,
      setInvestmentDetails,
    };
  },
  { persist: true }
);
