<template>
  <v-img
    v-if="props.photo !== ''"
    :src="require(`../assets/${props.photo}`)"
    alt="App Logo"
    max-width="100%"
    contain
  />
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(["photo"]);
</script>
