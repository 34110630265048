// Styles
import "@mdi/font/css/materialdesignicons.css";
import "@/styles/main.scss";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  theme: {
    defaultTheme: "PitchlyTheme",
    themes: {
      PitchlyTheme: {
        dark: false,
        colors: {
          background: "#F6F3F2",
          "secondary-background": "#F6D46B",
          primary: "#FF9933",
          "secondary-blue": "#4C6FBF",
          "secondary-blue-darken": "#0C1765",
          "secondary-pink": "#FDC8D7",
          text: "#000000",
          error: "#B00020",
          info: "#2196F3",
          success: "#4CAF50",
          warning: "#FB8C00",
        },
      },
    },
  },
});
