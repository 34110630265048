import { defineStore } from "pinia";
import { ref } from "vue";

export const useOnePagerStore = defineStore(
  "OnePagerStore",
  () => {
    const stages = ref(["Ideation", "Validation", "Launch", "Growth"]);
    const raisings = ref(["Seed", "Early Stage", "Growth Stage"]);
    const onePager = ref([
      {
        label: "Name of Startup",
        type: "textfield",
        model: null,
      },
      {
        label: "Startup Overview",
        subtitle: "(up to 90 words)",
        type: "textarea",
        model: null,
      },
      {
        label: "Startup Stage",
        type: "select",
        items: stages.value,
        model: ref([]),
      },
      {
        label: "Fundraising Round",
        type: "select",
        items: raisings.value,
        model: ref([]),
      },
      {
        label: "No. of Employees",
        type: "textfield",
        model: null,
      },
      {
        label: "Market Size",
        type: "textarea",
        model: null,
      },
      {
        label: "Amount Raising (PHP)",
        type: "textfield",
        model: null,
      },
      {
        label: "Use of Fund",
        type: "textarea",
        model: null,
      },
      {
        label: "Challenge",
        type: "textarea",
        model: null,
      },
      {
        label: "Solution",
        type: "textarea",
        model: null,
      },
      {
        label: "Market",
        type: "textarea",
        model: null,
      },
      {
        label: "Milestone",
        type: "textarea",
        model: null,
      },
      {
        label: "Revenue Model",
        type: "textarea",
        model: null,
      },
      {
        label: "Competitive Advantage",
        type: "textarea",
        model: null,
      },
      {
        label: "Exit",
        type: "textarea",
        model: null,
      },
      {
        label: "Founding Team",
        type: "textarea",
        model: null,
      },
    ]);

    const setOnePager = (payload) => {
      onePager.value = payload;
    };
    const prepopulate = (payload) => {
      const { name, description, stage, raising, amount } = payload;
      console.log(payload);

      onePager.value[0].model = name;
      onePager.value[1].model = description;
      onePager.value[2].model = stage;
      onePager.value[3].model = raising;
      onePager.value[6].model = amount;
    };

    return {
      stages,
      raisings,
      onePager,
      setOnePager,
      prepopulate,
    };
  },
  { persist: true }
);
