import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const usePitchStore = defineStore(
  "PitchStore",
  () => {
    const pitch = ref({
      onePager: {
        filename: "",
        url: "",
      },
      elevator: {
        filename: "",
        url: "",
      },
      pitchDeck: {
        filename: "",
        url: "",
      },
    });
    const currentPitch = ref("pitchDeck");

    const pitches = computed(() => {
      return [
        {
          label: "Pitch Deck",
          key: "pitchDeck",
          disabled: false,
        },
        {
          label: "Elevator Pitch",
          key: "elevator",
          disabled: false,
        },
        {
          label: "One-Pager",
          key: "onePager",
          disabled: false,
        },
      ];
    });

    const setPitch = (value) => {
      pitch.value = value;
    };
    const setCurrentPitch = (value) => {
      currentPitch.value = value;
    };
    const clearPitch = () => {
      pitch.value = null;
    };
    const setOnePagerFile = (payload) => {
      const { filename, url } = payload;
      pitch.value.onePager.filename = filename;
      pitch.value.onePager.url = url;
    };
    const setElevatorFile = (payload) => {
      const { filename, url } = payload;
      pitch.value.elevator.filename = filename;
      pitch.value.elevator.url = url;
    };
    const setPitchDeckFile = (payload) => {
      const { filename, url } = payload;
      pitch.value.pitchDeck.filename = filename;
      pitch.value.pitchDeck.url = url;
    };

    return {
      pitch,
      currentPitch,
      pitches,
      setPitch,
      setCurrentPitch,
      clearPitch,
      setOnePagerFile,
      setElevatorFile,
      setPitchDeckFile,
    };
  },
  { persist: true }
);
