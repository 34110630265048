<template>
  <v-toolbar color="secondary-background justify-content-center">
    <v-row class="main justify-content-space-between">
      <v-col md="2" class="justify-content-start align-center">
        <v-img
          :src="require('../assets/pitchly-logo-transparent.png')"
          alt="App Logo"
          max-width="100%"
          max-height="80%"
          contain
          class="pr-sm-0 pr-md-16"
        />
      </v-col>
      <v-col md="8" sm="10" class="justify-content-end">
        <v-row
          v-if="props.showSignUpLogIn"
          class="less-buttons justify-content-space-between align-center h-100"
        >
          <v-btn variant="text" to="/signup" class="no-padding">Sign-up</v-btn>
          <v-divider vertical thickness="3px" opacity="40%" color="black" />
          <v-btn variant="text" to="/login" class="no-padding">Log-in</v-btn>
        </v-row>
        <v-row
          v-else-if="props.editorView && !isMobile"
          class="more-buttons justify-content-space-between align-center"
        >
          <v-btn
            v-for="link in editorLinks"
            :key="link.label"
            :variant="route.path === link.path ? 'outlined' : 'text'"
            class="no-uppercase links"
            @click="router.push(link.path)"
            >{{ link.label }}</v-btn
          >
        </v-row>
        <v-row
          v-else-if="props.editorView && isMobile"
          class="justify-content-end align-center"
        >
          <v-menu>
            <template v-slot:activator="{ props: menu }">
              <v-btn
                density="compact"
                icon="mdi-menu"
                size="large"
                v-bind="mergeProps(menu)"
              />
            </template>

            <v-list class="bg-background">
              <v-list-item
                v-for="link in editorLinks"
                :key="link.label"
                :title="link.label"
                :value="link.label"
                :class="route.path === link.path ? 'current' : ''"
                @click="router.push(link.path)"
              />
            </v-list>
          </v-menu>
        </v-row>
        <v-row
          v-else-if="props.previewView && !isMobile"
          class="less-buttons justify-content-end align-center"
        >
          <v-btn
            v-for="link in previewLinks"
            :key="link.label"
            class="no-uppercase links"
            @click="clickPreview(link)"
            >{{ link.label }}</v-btn
          >
        </v-row>
        <v-row
          v-else-if="props.previewView && isMobile"
          class="less-buttons justify-content-end align-center"
        >
          <v-menu>
            <template v-slot:activator="{ props: menu }">
              <v-btn
                density="compact"
                icon="mdi-menu"
                size="large"
                v-bind="mergeProps(menu)"
              />
            </template>

            <v-list class="bg-background">
              <v-list-item
                v-for="link in pitches"
                :key="link.label"
                :title="link.label"
                :value="link.label"
                :class="currentPitch === link.key ? 'current' : ''"
                @click="setCurrentPitch(link.key)"
              />
              <v-divider />
              <v-list-item
                v-for="link in previewLinks"
                :key="link.label"
                :title="link.label"
                :value="link.label"
                @click="clickPreview(link)"
              >
                <template v-slot:prepend>
                  <v-btn
                    color="primary"
                    :icon="link.icon"
                    variant="text"
                  ></v-btn>
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
        <v-row
          v-else-if="props.investorView && !isMobile"
          class="less-buttons justify-content-end align-center"
        >
          <v-btn
            v-for="link in investorLinks"
            :key="link.label"
            class="no-uppercase links"
            @click="clickInvestor(link)"
            >{{ link.label }}</v-btn
          >
        </v-row>
        <v-row
          v-else-if="props.investorView && isMobile"
          class="less-buttons justify-content-end align-center"
        >
          <v-menu>
            <template v-slot:activator="{ props: menu }">
              <v-btn
                density="compact"
                icon="mdi-menu"
                size="large"
                v-bind="mergeProps(menu)"
              />
            </template>

            <v-list class="bg-background">
              <v-list-item
                v-for="link in pitches"
                :key="link.label"
                :title="link.label"
                :value="link.label"
                :class="currentPitch === link.key ? 'current' : ''"
                @click="setCurrentPitch(link.key)"
              />
              <v-divider />
              <v-list-item
                v-for="link in investorLinks"
                :key="link.label"
                :title="link.label"
                :value="link.label"
                @click="clickInvestor(link)"
              >
                <template v-slot:prepend>
                  <v-btn
                    color="primary"
                    icon="mdi-email-newsletter"
                    variant="text"
                  ></v-btn>
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-col>
    </v-row>
  </v-toolbar>
  <PublishDialog
    v-model="openPublishDialog"
    @close-dialog="closePublishDialog"
  />
  <SubscribeDialog
    v-model="openSubscribeDialog"
    @close-dialog="closeSubscribeDialog"
  />
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { defineProps, mergeProps } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useCommonStore } from "@/store/common";
import { usePitchStore } from "@/store/pitch";
import { storeToRefs } from "pinia";
import PublishDialog from "./Dialogs/PublishDialog.vue";
import SubscribeDialog from "./Dialogs/SubscribeDialog.vue";

const route = useRoute();
const router = useRouter();
const commonStore = useCommonStore();
const pitchStore = usePitchStore();

const { isMobile } = storeToRefs(commonStore);
const { currentPitch, pitches } = storeToRefs(pitchStore);

const { setCurrentPitch } = pitchStore;

const props = defineProps([
  "showSignUpLogIn",
  "editorView",
  "previewView",
  "investorView",
]);
const editorLinks = [
  {
    label: "My Pitch",
    path: "/pitch",
  },
  {
    label: "Analytics",
    path: "/analytics",
  },
  {
    label: "Newsletter",
    path: "/newsletter",
  },
  {
    label: "Account",
    path: "/account",
  },
];
const previewLinks = [
  {
    label: "Edit Pitch",
    path: "/pitch",
    icon: "mdi-file-edit",
  },
  {
    label: "Publish",
    icon: "mdi-publish",
  },
];
const investorLinks = [
  {
    label: "Follow this Fundraise",
  },
];
let openPublishDialog = ref(false);
let openSubscribeDialog = ref(false);

const clickPreview = (link) => {
  if (link.path) {
    router.push(link.path);
  } else {
    openPublishDialog.value = true;
  }
};
const clickInvestor = (link) => {
  if (link.path) {
    router.push(link.path);
  } else {
    openSubscribeDialog.value = true;
  }
};

const closePublishDialog = () => {
  openPublishDialog.value = false;
};
const closeSubscribeDialog = () => {
  openSubscribeDialog.value = false;
};

watchEffect(() => {
  console.log(route.path);
});
</script>

<style scoped>
.v-toolbar {
  height: 10vh;
  min-height: 70px;
}
.v-col {
  padding: 0;
}
.main {
  width: 100vw;
}
.v-btn {
  font-size: medium;
}
.v-divider {
  height: 50%;
  align-self: center;
}
.v-list {
  .current {
    background-color: #ff9933;
    color: white;
  }
}
.less-buttons {
  max-width: 300px;
}
.more-buttons {
  max-width: 800px;
}
.no-padding {
  padding: 0;
}
.links {
  margin-left: 3%;
}
</style>
