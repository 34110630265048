<template>
  <v-skeleton-loader :type="props.type" class="bg-background" />
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(["type"]);
</script>

<style scoped>
.v-skeleton-loader {
  width: 95%;
  height: 90%;
}
</style>
