<template>
  <v-textarea
    v-model="value"
    :label="props.label"
    :required="props.isRequired"
    density="compact"
    :rows="props.rows"
    no-resize
  />
</template>

<script setup>
import { defineProps, defineEmits, ref, watchEffect } from "vue";

const props = defineProps(["label", "isRequired", "rows"]);
const emit = defineEmits(["update-text"]);

const value = ref("");

watchEffect(() => {
  emit("update-text", value.value);
});
</script>

<style scoped>
.v-textarea {
  width: 100%;
}
</style>
