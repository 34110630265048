<template>
  <v-form fast-fail @submit.prevent class="bg-background">
    <v-select
      v-model="raising"
      :items="raisings"
      label="Fundraising Round*"
      :rules="[rules.required]"
    />
    <v-select
      v-model="offer"
      :items="offers"
      label="Offer to Investor*"
      :rules="[rules.required]"
    />
    <v-text-field
      v-if="isAmountTextFocused"
      :autofocus="isAmountTextFocused"
      v-model="rawAmount"
      label="Amount Raising (Peso)*"
      :rules="[rules.required, rules.positive]"
      @focus="isAmountTextFocused = true"
      @blur="isAmountTextFocused = false"
    />
    <v-text-field
      v-else
      v-model="formattedAmount"
      label="Amount Raising (Peso)*"
      :rules="[rules.required, rules.positive]"
      @click="isAmountTextFocused = true"
    />
  </v-form>
</template>

<script setup>
import { ref, watchEffect, defineEmits } from "vue";
import { useCommonStore } from "@/store/common";
import { useCompanyStore } from "@/store/company";
import { useOnePagerStore } from "@/store/onePager";
import { storeToRefs } from "pinia";

const emit = defineEmits(["disableNextButton"]);

const commonStore = useCommonStore();
const companyStore = useCompanyStore();
const onePagerStore = useOnePagerStore();
const { setInvestmentDetails } = companyStore;
const { rules } = storeToRefs(commonStore);
const { raisings } = storeToRefs(onePagerStore);

const offers = [
  "Equity Investment",
  "Convertible Note",
  "SAFE",
  "Revenue-based Financing",
  "Debt Financing",
  "Preferred Stock Offering",
];
let offer = ref(offers[0]);
let raising = ref(raisings.value[0]);
let rawAmount = ref(0);
let formattedAmount = ref("");
let isAmountTextFocused = ref(false);

watchEffect(() => {
  if (isNaN(rawAmount.value) || rawAmount.value < 1) {
    emit("disableNextButton", true);
  } else {
    formattedAmount.value = new Intl.NumberFormat("en-PH", {
      style: "currency",
      currency: "PHP",
    }).format(rawAmount.value);

    setInvestmentDetails({
      investmentTerm: raising.value,
      offer: offer.value,
      amountRaising: rawAmount.value,
    });

    emit("disableNextButton", false);
  }
});
</script>

<style scoped>
.v-form {
  width: 100%;
  margin-top: 3%;
}
</style>
