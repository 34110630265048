<template>
  <v-stepper alt-labels v-model="currentStep" class="bg-background">
    <template v-slot>
      <v-stepper-header>
        <template v-for="(step, index) in steps" :key="`${step.title}-step`">
          <v-stepper-item
            :complete="currentStep > index + 1"
            :value="index + 1"
            :title="step.title"
            :color="currentStep > index + 1 ? 'primary' : ''"
            :class="isMobile ? 'mobile-width-header' : ''"
          />

          <v-divider
            v-if="index + 1 !== steps.length"
            :key="step.title"
            :class="isMobile ? 'mobile-width-header' : ''"
          ></v-divider>
        </template>
      </v-stepper-header>

      <v-row class="content">
        <v-stepper-window>
          <v-stepper-window-item
            v-for="(step, index) in steps"
            :key="`${step.title}-content`"
            :value="index + 1"
          >
            <template v-slot>
              <p class="subheading body-size text-primary">
                {{ step.title }}
              </p>
              <component
                :is="step.content"
                @disable-next-button="disableNextButton"
              />
            </template>
          </v-stepper-window-item>
        </v-stepper-window>
        <v-row class="align-center">
          <v-btn variant="text" class="bg-error">Log-out</v-btn>
          <v-spacer />
          <v-btn variant="text" :disabled="currentStep < 0" @click="prevStep">{{
            currentStep === 1 ? "Cancel" : "Previous"
          }}</v-btn>
          <v-btn
            :disabled="disableNextStep"
            class="bg-primary"
            @click="nextStep"
            :loading="isSaving"
            >{{
              currentStep === steps.length ? "Save" : "Save and Next"
            }}</v-btn
          >
        </v-row>
      </v-row>
    </template>
  </v-stepper>

  <ErrorDialog
    v-model="openErrorDialog"
    title="Log-in failed!"
    :message="errorMsg"
    @close-dialog="closeErrorDialog"
  />
</template>

<script setup>
import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useCommonStore } from "@/store/common";
import { useUserStore } from "@/store/user";
import CompanyStep from "./AccountFormSteps/CompanyStep.vue";
import InvestmentStep from "./AccountFormSteps/InvestmentStep.vue";
import UserStep from "./AccountFormSteps/UserStep.vue";
import ErrorDialog from "../Dialogs/ErrorDialog.vue";

const router = useRouter();

const commonStore = useCommonStore();
const userStore = useUserStore();

const { isMobile } = storeToRefs(commonStore);
const { currentUser } = storeToRefs(userStore);

let currentStep = ref(1);
const steps = [
  {
    title: "Startup Details",
    content: CompanyStep,
  },
  {
    title: "Investment Details",
    content: InvestmentStep,
  },
  {
    title: "User Details",
    content: UserStep,
  },
];
let disableNextStep = ref(false);
let isSaving = ref(false);
let openErrorDialog = ref(false);
let errorMsg = ref("");

const prevStep = () => {
  if (currentStep.value > 1) {
    currentStep.value--;
  } else if (currentStep.value === 1) {
    router.push("/");
  }
};
const nextStep = async () => {
  if (currentStep.value < steps.length) {
    currentStep.value++;
  } else if (currentStep.value === steps.length) {
    isSaving.value = true;
    const email = currentUser.value.contactEmail;
    const password = currentUser.value.password;

    console.log(password + email);

    try {
      //edit details

      router.push("/pitch");
    } catch (e) {
      isSaving.value = false;
      errorMsg.value = e.message;
      openErrorDialog.value = true;
      console.log(e);
    }
  }
};
const disableNextButton = (value) => {
  disableNextStep.value = value;
};
const closeErrorDialog = () => {
  openErrorDialog.value = false;
};

watchEffect(() => {
  disableNextStep.value = currentStep.value > steps.length;
});
</script>

<style scoped>
.v-stepper {
  height: 80%;
  width: 90%;
}
.v-stepper-window {
  max-height: 70%;
  width: 100%;
  overflow-y: auto;
}
.v-btn {
  width: fit-content;
}
.mobile-width-header {
  max-width: 100px;
}
.content {
  height: 75%;
}
</style>
