<template>
  <v-dialog v-model="isActive" width="auto" @click:outside="closeDialog">
    <AideSection @close-dialog="closeDialog" />
  </v-dialog>
</template>

<script setup>
import { ref, defineEmits, watchEffect } from "vue";
import { useCommonStore } from "@/store/common";
import { storeToRefs } from "pinia";
import AideSection from "../AideSection.vue";

const emit = defineEmits(["close-dialog"]);
const commonStore = useCommonStore();

const { isMobile } = storeToRefs(commonStore);

let isActive = ref(true);

const closeDialog = () => {
  isActive.value = false;
  emit("close-dialog");
};

watchEffect(() => {
  if (!isMobile.value) {
    closeDialog();
  }
});
</script>
