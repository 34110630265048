<template>
  <v-card-text>
    <p>
      An elevator pitch is a brief, persuasive speech that succinctly outlines
      an idea, product, or service, designed to spark interest and provide
      essential information in the short time span of an elevator ride,
      typically 30 seconds.
    </p>
    <v-expansion-panels variant="accordion">
      <v-expansion-panel
        class="bg-background"
        expand-icon="mdi-menu-down"
        collapse-icon="mdi-menu-up"
        v-for="content in contents"
        :key="content.title"
      >
        <v-expansion-panel-title>{{ content.title }}</v-expansion-panel-title>
        <v-expansion-panel-text>{{ content.body }}</v-expansion-panel-text>
      </v-expansion-panel>
      <v-expansion-panel
        class="bg-background"
        expand-icon="mdi-menu-down"
        collapse-icon="mdi-menu-up"
      >
        <v-expansion-panel-title>Watch Sample Video</v-expansion-panel-title>
        <v-expansion-panel-text
          >Watch a sample video
          <a
            class="text-primary"
            href="https://youtu.be/W1ypbEDRhKc?si=R9nyg6ggkVhUq7rV"
            target="_blank"
            >here</a
          >,
          <a
            class="text-primary"
            href="https://youtu.be/rpppReb1Jr4?si=CQ759dmNZbn2lz5o"
            target="_blank"
            >here</a
          >, and
          <a
            class="text-primary"
            href="https://youtu.be/HYjnuL5dgkw?si=h9PJhCXXkI7s-Pfw"
            target="_blank"
            >here</a
          >
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card-text>
</template>

<script setup>
const contents = [
  {
    title: "Sample Elevator Pitch Spiel",
    body: "Hi, I’m Sarah, CEO of SolarBright. We install solar panels that cut energy bills by up to 40%. With rising electricity costs and a growing emphasis on sustainability, our solution offers immediate savings and a greener future. Already, we've empowered over 500 homes. Ready to make an impact?",
  },
  {
    title: "Trivia",
    body: "A 30-second spiel typically contains around 75 to 90 words, depending on the speaking pace. Most people speak at a rate of about 150 to 180 words per minute.",
  },
];
</script>

<style scoped>
p {
  margin-top: 5%;
  margin-bottom: 8%;
}
.v-expansion-panel-title {
  color: #ff9933 !important;
}
</style>
