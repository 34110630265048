<template>
  <v-row class="align-center justify-content-center">
    <v-form
      fast-fail
      @submit.prevent
      class="bg-background align-center justify-content-center"
    >
      <v-text-field label="Subject" :Required="true" v-model="title" />
      <v-textarea
        v-model="body"
        label="Body"
        :required="true"
        density="compact"
        :rows="15"
        no-resize
      />
      <v-btn class="bg-primary" @click="addToNewsletterQueue">{{
        isRepublish ? "Re-publish" : "Publish"
      }}</v-btn>
    </v-form>
  </v-row>
</template>

<script setup>
import { ref, watchEffect, watch } from "vue";
import { useNewsletterStore } from "@/store/newsletter";
import { useUserStore } from "@/store/user";
import { storeToRefs } from "pinia";
import { db } from "@/firebase/init";
import { addDoc, collection } from "firebase/firestore";


const newsletterStore = useNewsletterStore();
const userStore = useUserStore();

const { selectedNewsletter, newsletters } = storeToRefs(newsletterStore);
const { publishNewsletter, clearNewsletter } = newsletterStore;
const { currentUser } = storeToRefs(userStore);

let title = ref("");
let body = ref("");
const newsletter = ref();
let isRepublish = ref(false);

const addToNewsletterQueue = async () => {
  const newNewsletter = {
    date: new Date(),
    title: title.value,
    body: body.value,
    isSent: false,
    companyID: currentUser.value.companyuid,
  };
  console.log(newNewsletter);
  const newsletterRef = await addDoc(collection(db, "newsletters"), newNewsletter);

console.log("Document written with ID: ",newsletterRef.id);

  publishNewsletter(newNewsletter);
  title.value = "";
  body.value = "";
  clearNewsletter();
};

watch(
  selectedNewsletter,
  () => {
    if (selectedNewsletter.value) {
      newsletter.value = selectedNewsletter.value;
    }
  },
  { immediate: true }
);
watchEffect(() => {
  if (selectedNewsletter.value) {
    title.value = newsletter.value?.title;
    body.value = newsletter.value?.body;
    clearNewsletter();
  }
  isRepublish.value =
    newsletters.value.findIndex(
      (x) => x.title === title.value && x.body === body.value
    ) >= 0;
});
</script>

<style scoped>
.v-row {
  width: 80%;
  height: 80vh;
  flex-direction: column;
}
.v-form {
  width: 100%;
  height: fit-content;
  flex-direction: column;
}
.v-btn {
  width: 50%;
}
</style>
