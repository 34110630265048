import { defineStore } from "pinia";
import { ref, computed } from "vue";

export const useNewsletterStore = defineStore("NewsletterStore", () => {
  const selectedNewsletter = ref();
  const newsletters = ref([]);

  const isEmpty = computed(
    () =>
      selectedNewsletter.value?.title === "" &&
      selectedNewsletter.value?.body === ""
  );

  const setNewsletter = (value) => {
    selectedNewsletter.value = value;
  };

  const addNewsletters = (payload) => {
    const { newnewsletters } = payload;
    newsletters.value.push(newnewsletters);
    console.log("storevalue", " => ",newnewsletters)
  };


  const publishNewsletter = (newsletter) => {
    if (!isEmpty.value) {
      newsletters.value.push(newsletter);
      newsletters.value.sort((a, b) => b.date - a.date);
    }
  };
  const clearNewsletter = () => {
    selectedNewsletter.value = null;
  };

  return {
    selectedNewsletter,
    newsletters,
    isEmpty,
    setNewsletter,
    publishNewsletter,
    clearNewsletter,
    addNewsletters
  };
});
